/* eslint-disable @typescript-eslint/unbound-method */
import { NavigateFunction } from 'react-router-dom';
import {
  AGENT_TYPES,
  AgentData,
  AgentSourceFile,
  AgentSpecificReviewResponseType,
  ComplianceStatus,
  ConfidenceTypes,
  GapAssessmentTypes,
  ReviewResponseData,
  ReviewSourceTypes,
  SOC2ComplianceStatusDeprecated,
  Soc2GapReviewResponseTableRow,
} from '../types';
import { userStateSelector } from '@/shared/states/user';
import {
  createAgentSession,
  getAgentSession,
  updateAgentSessionStep,
} from '@/modules/sessions/requests';
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  CommonRagCreateRequest,
  SOC2GapAgentSession,
} from '@/modules/sessions/types';
import { getAgentData, getAgentStateActions } from '../states';
import { getRAGExcelFromJSON } from '../requests/';
import { getSessionJWT } from '@/infra/stytch';
import { handleSocketResponse } from '../utils/socket-response';
import { getResponseTypes } from '../utils/get-response-type';
import { SOC2GapReviewResponse } from '../types/risk-and-gap';
import { getSignedUrl } from '@/shared/requests/get-signed-url';
import { uploadFileReq } from '@/shared/requests/upload-file';
import { get } from '@/infra/rest';
import { v4 as uuid } from 'uuid';
import { getRenderType } from '../utils/get-render-type';
import { convertSnakeToCapitalized } from '../utils/snake-to-capital';
import { addNotification } from '@/shared/states/notification';
import { AiResponseType } from '@/shared/types/user';
import { emitRagCreate } from '../requests';

interface ProcessFileForSoc2GapAssessmentArgs {
  name: string;
  sourceFiles: AgentSourceFile[];
  navigate: NavigateFunction;
}

export const processFileForSoc2GapAssessment = async ({
  name,
  sourceFiles,
  navigate,
}: ProcessFileForSoc2GapAssessmentArgs) => {
  const agentSessionType = AgentSessionType.SOC2_GAP;
  const selectedResponse = userStateSelector.getState().aiResponseType;
  const source_urls = sourceFiles.map((file) => file.url);

  const { response_mode, response_quality } =
    getResponseTypes(selectedResponse);

  const gapAssessmentRequest: CommonRagCreateRequest = {
    doc_type: 'json',
    response_quality,
    source_urls,
  };

  const {
    data: { session, steps = [] },
  } = await createAgentSession({
    name: name ?? 'SOC 2 Gap Assessment',
    type: agentSessionType,
    [agentSessionType]: gapAssessmentRequest,
  });

  if (!session || !session.id) {
    throw new Error('An error occurred');
  }

  const agentType = AGENT_TYPES.GAP_ASSESSMENT;
  const agentSubType = GapAssessmentTypes.SOC2;
  const statusInProgressSessionStep = AgentSessionStepType.SOC2_TYPE2_AUDIT;
  const task = 'soc2_type2_audit';
  const statusCompleteSessionSteps = [
    AgentSessionStepType.LOAD_TEMPLATE,
    AgentSessionStepType.EXTRACT_CONTROLS,
  ];

  const stepData = steps.reduce((acc, step) => {
    if (statusCompleteSessionSteps.includes(step.type)) {
      step.status = AgentSessionStatus.COMPLETE;
    }

    if (statusInProgressSessionStep === step.type) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }

    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);

  const agentData: AgentData<typeof agentType, typeof agentSubType> = {
    agentType: agentType,
    sessionData: session as SOC2GapAgentSession,
    stepData,
    subType: agentSubType,
    responseQuality: selectedResponse,
    mainData: {
      sourceFilesUrls: new Map(
        sourceFiles.map((file) => [file.fileName, file])
      ),
      approvedIds: [],
      editedIds: [],
    },
  };

  const agent_session_step_id =
    steps.find((step) => step.type === statusInProgressSessionStep)?.id ??
    steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE)
      ?.id ??
    '';

  const { setAgentData } = getAgentStateActions();
  setAgentData(session.id, agentData);

  await emitRagCreate(
    {
      agent_session_id: session.id,
      agent_session_step_id,
      token: await getSessionJWT(),
      rag_input: gapAssessmentRequest,
      response_mode,
      task: task,
    },
    (response: any) => {
      handleSocketResponse(response, navigate);
    }
  );
  navigate({
    pathname: `/agent/${session.id}/`,
  });
};

interface FinalGapReviewResponse extends SOC2GapReviewResponse {
  approved: boolean;
  edited: boolean;
  id: string;
}

export const generateFinalSoc2GapJSON = (agentId: string) => {
  const agentData = getAgentData<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.SOC2
  >(agentId);

  if (!agentData) {
    throw new Error('An error occurred');
  }
  const { mainData } = agentData;
  const { reviewResponseData } = mainData;
  if (!reviewResponseData) {
    throw new Error('An error occurred');
  }
  const approvedIds = mainData.approvedIds;
  const editedIds = mainData.editedIds;
  const finalGapJson: FinalGapReviewResponse[] = [];

  reviewResponseData.forEach((item, id) => {
    const gap: FinalGapReviewResponse = {
      approved: approvedIds.includes(id),
      edited: editedIds.includes(id),
      id,
      gaps: item.find((item) => item.key === 'gaps')?.value as string,
      recommendations: item.find((item) => item.key === 'recommendations')
        ?.value as string,
      confidence: item.find((item) => item.key === 'confidence')
        ?.value as ConfidenceTypes,
      justification: item.find((item) => item.key === 'justification')
        ?.value as string,
      sources: item.find((item) => item.key === 'sources')
        ?.value as ReviewSourceTypes[],
      observations: item.find((item) => item.key === 'observations')
        ?.value as string,
      compliance_status: item.find((item) => item.key === 'compliance_status')
        ?.value as SOC2ComplianceStatusDeprecated,
      llm_instructions: item.find((item) => item.key === 'llm_instructions')
        ?.value as string,
      trust_id: item.find((item) => item.key === 'trust_id')?.value as string,
      trust_services_criteria: item.find(
        (item) => item.key === 'trust_services_criteria'
      )?.value as string,
      control_number: item.find((item) => item.key === 'control_number')
        ?.value as number,
      control: item.find((item) => item.key === 'control')?.value as string,
      category: item.find((item) => item.key === 'category')?.value as string,
      assessment_criteria: item.find(
        (item) => item.key === 'assessment_criteria'
      )?.value as string[],
      retriever_questions: item.find(
        (item) => item.key === 'retriever_questions'
      )?.value as string[],
      zania_control_id: item.find((item) => item.key === 'zania_control_id')
        ?.value as string,
      control_id: item.find((item) => item.key === 'control_id')
        ?.value as string,
    };
    finalGapJson.push(gap);
  });

  return finalGapJson;
};

export const handleAutoSaveSoc2 = async (
  agentId: string,
  markAsComplete?: boolean
) => {
  const risks = JSON.stringify(generateFinalSoc2GapJSON(agentId));
  const blob = new Blob([risks], { type: 'application/json' });
  const agentData = getAgentData<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.SOC2
  >(agentId);

  if (!agentData) {
    throw new Error('An error occurred');
  }

  const { stepData } = agentData;
  const { staleUrl } = agentData.mainData;

  const { setStaleUrl, updateAgentStepData } = getAgentStateActions();

  const editStepData = stepData?.find(
    (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
  );

  if (!editStepData) {
    throw new Error('An occurred while saving');
  }

  const stepUrl = editStepData?.data?.url;

  let currentStaleUrl = staleUrl || stepUrl;
  let markAsCompleteDone = false;

  if (!currentStaleUrl) {
    const signedUrl = await getSignedUrl({
      file_names: ['updated_gaps.json'],
      max_age: 86400,
    });
    const updatedStep = {
      ...editStepData,
      data: {
        url: signedUrl[0],
      },
      status: markAsComplete
        ? AgentSessionStatus.COMPLETE
        : AgentSessionStatus.IN_PROGRESS,
    };
    const updatedSteps = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedSteps);
    await updateAgentSessionStep(updatedStep);
    currentStaleUrl = signedUrl[0];
    markAsCompleteDone = true;
    setStaleUrl(agentId, currentStaleUrl);
  }
  const expiryDate = new URL(currentStaleUrl).searchParams.get('se');
  if (expiryDate) {
    const expiry = new Date(expiryDate);
    const currentTime = new Date();
    const diff = expiry.getTime() - currentTime.getTime();
    if (diff < 0) {
      const signedUrl = await getSignedUrl({
        stale_urls: [currentStaleUrl],
        max_age: 86400,
      });
      currentStaleUrl = signedUrl[0];
      setStaleUrl(agentId, currentStaleUrl);
    }
  }
  await uploadFileReq(currentStaleUrl, blob);
  if (markAsComplete && !markAsCompleteDone) {
    const updatedStep = {
      ...editStepData,
      data: {
        url: currentStaleUrl,
      },
      status: AgentSessionStatus.COMPLETE,
    };
    await updateAgentSessionStep(updatedStep);
    const updatedStepData = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedStepData);
  }
};

export const processFileForSOC2Type2Audit = async (
  url: string,
  sessionId: string
) => {
  const gapJson = await get<FinalGapReviewResponse[]>({
    url,
    isAuthRequired: false,
  });

  // Sort gapJson in ascending order of trust_id

  const session = await getAgentSession(sessionId ?? '');
  const ids: string[] = [];
  const keysToOmit: string[] = [
    'id',
    'confidence',
    'approved',
    'edited',
    'llm_instructions',
    'trust_id',
    'trust_services_criteria',
    'control_number',
    'control',
    'category',
    'assessment_criteria',
    'retriever_questions',
    'control_id',
    'zania_control_id',
    'justification',
  ];
  const tableRows: Soc2GapReviewResponseTableRow[] = [];
  const approvedIds: string[] = [];
  const editedIds: string[] = [];
  const reviewResponse = new Map<
    string,
    ReviewResponseData<
      keyof AgentSpecificReviewResponseType<
        AGENT_TYPES.GAP_ASSESSMENT,
        GapAssessmentTypes.SOC2
      >
    >[]
  >(
    gapJson.map((gap) => {
      const id = uuid();
      ids.push(id);
      const tableRow: Soc2GapReviewResponseTableRow = {
        id: id,
        confidence: gap.confidence ?? '',
        sources: gap.sources ?? [],
        tag: gap.trust_id ?? '',
        control: gap.control ?? '',
        compliant: (gap.compliance_status
          ? gap.compliance_status.replace(/ /g, '-')
          : '') as ComplianceStatus,
        status: gap.approved ? 'approved' : gap.edited ? 'edited' : 'none',
      };
      tableRows.push(tableRow);
      if (gap.approved) {
        approvedIds.push(id);
      }
      if (gap.edited) {
        editedIds.push(id);
      }

      const responseData = Object.entries({ ...gap }).reduce(
        (acc, [key, value]) => {
          const data: ReviewResponseData<
            keyof AgentSpecificReviewResponseType<
              AGENT_TYPES.GAP_ASSESSMENT,
              GapAssessmentTypes.SOC2
            >
          > = {
            type: getRenderType(key),
            value: value,
            key: key as keyof AgentSpecificReviewResponseType<
              AGENT_TYPES.GAP_ASSESSMENT,
              GapAssessmentTypes.SOC2
            >,
            title: convertSnakeToCapitalized(key),
            shouldRender: !keysToOmit.includes(key),
          };
          acc.push(data);
          return acc;
        },
        [] as ReviewResponseData<
          keyof AgentSpecificReviewResponseType<
            AGENT_TYPES.GAP_ASSESSMENT,
            GapAssessmentTypes.SOC2
          >
        >[]
      );

      const orderedKeys = [
        'observations',
        'gaps',
        'recommendations',
        'compliance_status',
        'sources',
        'justification',
      ];
      responseData.sort((a, b) => {
        const indexA = orderedKeys.indexOf(a.key as string);
        const indexB = orderedKeys.indexOf(b.key as string);
        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
        return 0;
      });

      return [id, responseData];
    })
  );

  tableRows.sort((a, b) => {
    const trustIdA = a.tag || '';
    const trustIdB = b.tag || '';

    const [categoryA, numberA] = trustIdA.split('.');
    const [categoryB, numberB] = trustIdB.split('.');

    const categoryComparison = categoryA.localeCompare(categoryB);
    if (categoryComparison !== 0) {
      return categoryComparison;
    }

    return Number(numberA) - Number(numberB);
  });

  const currentData = getAgentData<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.SOC2
  >(sessionId);
  if (!currentData) {
    throw new Error('An error occurred');
  }

  const stepData = currentData.stepData.map((step) => {
    if (step.type === AgentSessionStepType.SOC2_TYPE2_AUDIT) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
    }
    return step;
  });

  const agentData: Partial<
    AgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>
  > = {
    agentType: AGENT_TYPES.GAP_ASSESSMENT,
    sessionData: session.data.session as SOC2GapAgentSession,
    stepData,
    mainData: {
      ...currentData.mainData,
      reviewResponseData: reviewResponse,
      reviewResponseIds: ids,
      tableRows,
      searchFor: ['control'],
      searchTerm: '',
      approvedIds,
      editedIds,
    },
  };

  const { updateAgentData } = getAgentStateActions();

  updateAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>(
    sessionId,
    agentData
  );
};

export const getSoc2JsonFromApi = async (sessionId: string) => {
  try {
    const gaps = JSON.stringify(generateFinalSoc2GapJSON(sessionId));
    const blob = new Blob([gaps], { type: 'application/json' });

    const signedUrl = await getSignedUrl({
      file_names: ['updated_gap.json'],
      max_age: 86400,
    });

    await uploadFileReq(signedUrl[0], blob);
    const agentData = getAgentData<
      AGENT_TYPES.GAP_ASSESSMENT,
      GapAssessmentTypes.SOC2
    >(sessionId);
    if (!agentData) {
      throw new Error('An error occurred');
    }
    const { responseQuality, sessionData, stepData } = agentData;

    const data = await getRAGExcelFromJSON({
      task: 'soc2_type2_audit',
      output_format: 'excel',
      json_url: signedUrl[0],
      response_quality: responseQuality ?? AiResponseType.LITE,
    });

    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();

    setFinalFileUrl(sessionId, data.data.file_url);

    const response = await fetch(data.data.file_url);
    const fileBlob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `${sessionData.name}_gap_assessment.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    addNotification({
      type: 'success',
      title: 'Downloading your Report...',
      message:
        'If your report download does not start within 15 seconds, please use the download button',
    });

    const downloadStepData = stepData?.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT
    );
    const updatedStepData = stepData.map((step) => {
      if (step.id === downloadStepData?.id) {
        return { ...step, status: AgentSessionStatus.COMPLETE };
      }
      return step;
    });

    if (downloadStepData?.id) {
      await updateAgentSessionStep({
        ...downloadStepData,
        data: {
          url: data.data.file_url,
        },
        status: AgentSessionStatus.COMPLETE,
      });
    }

    updateAgentStepData(sessionId, updatedStepData);
  } catch (error) {
    addNotification({
      type: 'error',
      message: 'Error in downloading the file',
      title: 'Error',
    });
  }
};
