/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getSessionJWT } from '@/infra/stytch';
import {
  emitQuestionnaireFiller,
  emitQuestionnaireStructure,
  getExcelFromJSON,
} from '../requests/questionnaire';
import { getColumnIndex } from '../utils';
import { v4 as uuid } from 'uuid';
import { getFileContent, getSheetData } from '../utils/excel-parser';
import { get } from '@/infra/rest';
import { getSignedUrl } from '@/shared/requests/get-signed-url';
import { uploadFileReq } from '@/shared/requests/upload-file';
import { NavigateFunction } from 'react-router-dom';
import { ROUTES } from '@/shared/constants/routes';
import { addNotification } from '@/shared/states/notification';
import {
  createAgentSession,
  getAgentSession,
  updateAgentSessionStep,
} from '@/modules/sessions/requests';
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  QaAgentSession,
} from '@/modules/sessions/types';
import { getFileNameFromUrl } from '../utils/get-filename-from-url';
import {
  ColumnMappingData,
  QuestionnaireFileData,
  QuestionnaireFiller,
  QuestionnaireMapping,
  QuestionReviewReponse,
  SheetStatus,
} from '../types/questionnaire';
import {
  AGENT_TYPES,
  AgentData,
  AgentSourceFile,
  AgentSpecificReviewResponseType,
  ConfidenceTypes,
  QuestionnaireTableRow,
  QuestionnaireTypes,
  ReviewResponseData,
  ReviewSourceTypes,
} from '../types';
import { userStateSelector } from '@/shared/states/user';
import {
  QAFillerRequest,
  Question,
  QuestionnaireStructure,
} from '../types/questionnaire';
import { dataNotFound } from '../types';
import { getAgentData, getAgentStateActions } from '../states';
import { getRenderType } from '../utils/get-render-type';
import { convertSnakeToCapitalized } from '../utils/snake-to-capital';
import { getResponseTypes } from '../utils/get-response-type';

interface ProcessFilesForMappingArgs {
  questionnaireFile: {
    file: File;
    url: string;
  };
  sourceFiles: AgentSourceFile[];
  navigate?: NavigateFunction;
  name?: string;
}

export const processFilesForMapping = async ({
  questionnaireFile,
  sourceFiles,
  name,
  navigate,
}: ProcessFilesForMappingArgs) => {
  const { file, url } = questionnaireFile;

  const { content } = await getFileContent(file);
  const { sheetNames, workbook } = await getSheetData(content);

  const selectedResponse = userStateSelector.getState().aiResponseType;
  let selectedSheet = '';
  const fileDetails: QuestionnaireFileData = {
    url,
    fileName: file?.name ?? getFileNameFromUrl(url),
    workbook,
    sheetNames,
    sheetData: {},
  };

  for (let i = 0; i < sheetNames.length; i++) {
    if (!sheetNames[i].endsWith('::hidden')) {
      selectedSheet = sheetNames[i];
      break;
    }
  }

  const qa_structure: QAFillerRequest = {
    excel_url: url,
    is_light: true,
    ...(sourceFiles.length > 1
      ? { multiple_source_urls: sourceFiles.map((f) => f.url) }
      : {
          kb_url: sourceFiles[0].url,
        }),
  };

  const {
    data: { session, steps },
  } = await createAgentSession({
    name: name ?? 'Security Questionnaire',
    type: AgentSessionType.QA_FILLER,
    [AgentSessionType.QA_FILLER]: qa_structure,
  });

  if (!session || !session.id) {
    throw new Error('An error occurred');
  }

  const stepData = steps.reduce((acc, step) => {
    if (step.type === AgentSessionStepType.QA_EXCEL_STRUCTURE_MAPPING) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }
    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);

  const { response_mode, response_quality } =
    getResponseTypes(selectedResponse);

  const agentData: AgentData<AGENT_TYPES.QUESTIONNAIRE, QuestionnaireTypes.QA> =
    {
      agentType: AGENT_TYPES.QUESTIONNAIRE,
      subType: QuestionnaireTypes.QA,
      sessionData: session as QaAgentSession,
      stepData,
      responseQuality: response_quality,
      mainData: {
        sourceFilesUrls: new Map(
          sourceFiles.map((file) => [file.fileName, file])
        ),
        questionnaireFileData: fileDetails,
        selectedSheet,
        approvedIds: [],
        editedIds: [],
        questionnaireMapping: {},
        questionnaireFiller: [],
      },
    };

  const { setAgentData, setSelectedSheet } = getAgentStateActions();
  setAgentData(session.id, agentData);
  await setSelectedSheet(session.id, selectedSheet);
  const agent_session_step_id =
    steps.find(
      (step) => step.type === AgentSessionStepType.QA_EXCEL_STRUCTURE_MAPPING
    )?.id ?? '';

  await emitQuestionnaireStructure(
    {
      token: await getSessionJWT(),
      agent_session_id: session.id,
      agent_session_step_id,
      qa_structure,
      response_mode,
      response_quality,
    },
    (response: any) => {
      if (response.status === false) {
        if (
          !!response?.message &&
          response?.message.length > 0 &&
          response.message === 'Token expired'
        ) {
          void processFilesForMapping({
            questionnaireFile,
            sourceFiles,
            name,
            navigate,
          });
          return;
        }
        addNotification({
          message:
            typeof response?.message === 'string' &&
            response?.message.length > 0
              ? response?.message
              : 'Error in getting the structure of the questionnaire',
          type: 'error',
        });
        navigate?.(`/${ROUTES.AGENT}`);
      }
    }
  );

  navigate?.(`/${ROUTES.AGENT}/${session.id}`);
};

const createColumnMapping = (columnLetters: string[]) => {
  return columnLetters.reduce((acc, col) => {
    if (!col || col === dataNotFound) return acc;
    acc.push({
      columnLetter: col,
      columnIndex: getColumnIndex(col),
    });
    return acc;
  }, [] as ColumnMappingData[]);
};

export const applyMappingFromAI = async (
  agentId: string,
  data: QuestionnaireStructure[]
) => {
  return new Promise<QuestionnaireMapping>((resolve) => {
    const finalData: QuestionnaireMapping = {};
    const { setSheetMappingData } = getAgentStateActions();
    const agentData = getAgentData<
      AGENT_TYPES.QUESTIONNAIRE,
      QuestionnaireTypes.QA
    >(agentId);
    if (!agentData) return;
    data.forEach(({ response, sheet_number }) => {
      const sheetName =
        agentData.mainData.questionnaireFileData.sheetNames[sheet_number];
      if (Object.keys(response).length === 0) {
        finalData[sheetName] = {
          questionStartRow: null,
          questions: [],
          comments: [],
          answers: [],
          questionType: [],
        };
      } else {
        const questions =
          response.questions && typeof response.questions === 'object'
            ? createColumnMapping(response.questions.columnLetters)
            : [];

        const comments =
          response.comments && typeof response.comments === 'object'
            ? createColumnMapping(response.comments.columnLetters)
            : [];

        const answers =
          response.answers && typeof response.answers === 'object'
            ? createColumnMapping(response.answers.columnLetters)
            : [];

        const questionType =
          response.answerType && typeof response.answerType === 'object'
            ? createColumnMapping(response.answerType.columnLetters)
            : [];

        finalData[sheetName] = {
          questionStartRow:
            typeof response.questionStartRow === 'number'
              ? response.questionStartRow
              : null,
          questions,
          comments,
          answers,
          questionType,
        };
      }
      setSheetMappingData(agentId, {
        sheetName,
        mappingData: finalData[sheetName],
      });
    });

    resolve(finalData);
  });
};

export const generateResponseData = async ({
  agentId,
}: {
  agentId: string;
}) => {
  const agentData = getAgentData<
    AGENT_TYPES.QUESTIONNAIRE,
    QuestionnaireTypes.QA
  >(agentId);
  const mapping = agentData?.mainData.questionnaireMapping;
  const stepsData = agentData?.stepData;
  const questionnaireFile = agentData?.mainData.questionnaireFileData;
  const sheetNames = questionnaireFile?.sheetNames;
  const agent_session_id = agentData?.sessionData.id;
  const knowledgeBaseFiles = agentData?.mainData.sourceFilesUrls;

  if (
    !mapping ||
    !stepsData ||
    !questionnaireFile ||
    !sheetNames ||
    !agent_session_id ||
    !knowledgeBaseFiles
  )
    return;

  const fillerData = Object.entries(mapping).reduce(
    (acc, [sheetName, sheetData]) => {
      const sheetIndex = sheetNames.indexOf(sheetName);
      if (sheetIndex === -1 || sheetData.status === SheetStatus.IGNORED)
        return acc;
      const sheetFiller: QuestionnaireFiller = {
        sheet_name: sheetIndex,
        metadata: {
          questions_start_row:
            typeof sheetData.questionStartRow === 'number'
              ? sheetData.questionStartRow
              : null,
          question_col_index: sheetData.questions[0].columnIndex,
          question_type_column:
            sheetData.questionType.length > 0
              ? sheetData.questionType[0].columnIndex
              : null,
          comment_col_index:
            sheetData.comments.length > 0
              ? sheetData.comments[0].columnIndex
              : null,
          answer_col_indexes: sheetData.answers.map((a) => a.columnIndex),
          answer_values:
            sheetData.answers.length > 1
              ? sheetData.answers.reduce((acc, a) => {
                  if (a.answerType) acc.push(a.answerType);
                  else acc.push('');
                  return acc;
                }, [] as string[])
              : null,
        },
      };
      acc.push(sheetFiller);
      return acc;
    },
    [] as QuestionnaireFiller[]
  );

  const { setQuestionnaireFiller, updateAgentStepData } =
    getAgentStateActions();

  setQuestionnaireFiller(agentId, fillerData);
  let manualMappingStepData: AgentSessionStep | undefined = undefined;
  const updatedStepData = stepsData.reduce((acc, step) => {
    if (step.type === AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING) {
      step.status = AgentSessionStatus.COMPLETE;
      step.data = {
        input: {
          ...step.data?.input,
        },
        output: {
          required_document_type: 'json',
          is_light: true,
          ...(knowledgeBaseFiles && knowledgeBaseFiles.size > 1
            ? {
                multiple_source_urls: Array.from(
                  knowledgeBaseFiles.values()
                ).map((f) => f.url),
              }
            : {
                kb_url: knowledgeBaseFiles?.values()?.next()?.value?.url || '',
              }),
          excel_url: questionnaireFile.url,
          excel_meta_data: fillerData,
        },
      };
      manualMappingStepData = step;
    }

    if (
      [
        AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING,
        AgentSessionStepType.EXTRACT_QUESTIONS,
      ].includes(step.type)
    ) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.QA_FILLER) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }
    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);

  if (manualMappingStepData) {
    await updateAgentSessionStep(manualMappingStepData);
  }
  updateAgentStepData(agent_session_id, updatedStepData);

  await emitQuestionnaireFiller(
    {
      token: await getSessionJWT(),
      agent_session_id,
      agent_session_step_id:
        stepsData.find((step) => step.type === AgentSessionStepType.QA_FILLER)
          ?.id ?? '',
      qa_filler: {
        required_document_type: 'json',
        is_light: true,
        ...(knowledgeBaseFiles && knowledgeBaseFiles.size > 1
          ? {
              multiple_source_urls: Array.from(knowledgeBaseFiles.values()).map(
                (f) => f.url
              ),
            }
          : {
              kb_url: knowledgeBaseFiles?.values()?.next()?.value?.url || '',
            }),
        excel_url: questionnaireFile.url,
        excel_meta_data: fillerData,
      },
    },
    (response: any) => {
      if (response.status === false) {
        addNotification({
          message:
            typeof response?.message === 'string' &&
            response?.message.length > 0
              ? response?.message
              : 'Error in generating the response data',
          type: 'error',
        });
      }
    }
  );
};

export const processFileForReview = async (url: string, sessionId: string) => {
  const questionsJson = await get<Question[][]>({
    url,
    isAuthRequired: false,
  });
  const session = await getAgentSession(sessionId);

  const ids: string[] = [];
  const keysToOmit: string[] = [
    'id',
    'approved',
    'edited',
    'question_id',
    'question_text',
    'confidence',
    'justification',
    'confidence',
    'llm_instructions',
    'trust_id',
    'trust_services_criteria',
    'control_number',
    'control',
    'category',
    'assessment_criteria',
    'retriever_questions',
    'control_id',
    'zania_control_id',
    'justification',
  ];
  const tableRows: QuestionnaireTableRow[] = [];
  const approvedIds: string[] = [];
  const editedIds: string[] = [];
  const reviewResponse = new Map<
    string,
    ReviewResponseData<
      keyof AgentSpecificReviewResponseType<
        AGENT_TYPES.QUESTIONNAIRE,
        QuestionnaireTypes.QA
      >
    >[]
  >(
    questionsJson.flat().map((question) => {
      const id = uuid();
      ids.push(id);
      const tableRow: QuestionnaireTableRow = {
        id,
        question: question.question_text,
        confidence: question.confidence,
        sources: question.sources.map((source) => ({
          source_file_name: source,
          file_type: '',
        })),
        status: question.approved ? 'approved' : question.edited ? 'edited' : 'none',
      };
      tableRows.push(tableRow);
      if (question.approved) {
        approvedIds.push(id);
      }
      if (question.edited) {
        editedIds.push(id);
      }
      return [
        id,
        Object.entries(question).reduce(
          (acc, [key, value]) => {
            const data: ReviewResponseData<
              keyof AgentSpecificReviewResponseType<
                AGENT_TYPES.QUESTIONNAIRE,
                QuestionnaireTypes.QA
              >
            > = {
              type: getRenderType(key),
              value:
                key === 'sources'
                  ? (value as string[]).map((source) => ({
                      source_file_name: source,
                      file_type: '',
                    }))
                  : value,
              key: key as keyof AgentSpecificReviewResponseType<
                AGENT_TYPES.QUESTIONNAIRE,
                QuestionnaireTypes.QA
              >,
              title: convertSnakeToCapitalized(key),
              shouldRender: !keysToOmit.includes(key),
            };
            acc.push(data);
            return acc;
          },
          [] as ReviewResponseData<
            keyof AgentSpecificReviewResponseType<
              AGENT_TYPES.QUESTIONNAIRE,
              QuestionnaireTypes.QA
            >
          >[]
        ),
      ];
    })
  );
  const currentData = getAgentData<
    AGENT_TYPES.QUESTIONNAIRE,
    QuestionnaireTypes.QA
  >(sessionId);
  if (!currentData) {
    throw new Error('An error occurred');
  }

  const stepData = currentData.stepData.map((step) => {
    if (
      [
        AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING,
        AgentSessionStepType.EXTRACT_QUESTIONS,
        AgentSessionStepType.QA_FILLER,
      ].includes(step.type)
    ) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
    }
    return step;
  });

  const agentData: Partial<
    AgentData<AGENT_TYPES.QUESTIONNAIRE, QuestionnaireTypes.QA>
  > = {
    agentType: AGENT_TYPES.QUESTIONNAIRE,
    sessionData: session.data.session as QaAgentSession,
    stepData,
    mainData: {
      ...currentData.mainData,
      reviewResponseData: reviewResponse,
      reviewResponseIds: ids,
      tableRows,
      searchFor: ['question'],
      searchTerm: '',
      approvedIds,
      editedIds,
    },
  };

  const { updateAgentData } = getAgentStateActions();

  updateAgentData<AGENT_TYPES.QUESTIONNAIRE, QuestionnaireTypes.QA>(
    sessionId,
    agentData
  );
};

interface ProcessManualMappingArgs {
  agent_session_id: string;
  structureData?: QuestionnaireStructure[];
  shouldNavigate?: boolean;
  navigate?: NavigateFunction;
}

export const processManualMapping = async ({
  agent_session_id,
  structureData,
  shouldNavigate,
  navigate,
}: ProcessManualMappingArgs) => {
  const { data: sessionData } = await getAgentSession(agent_session_id ?? '');
  const { updateAgentStepData } = getAgentStateActions();
  let data: QuestionnaireMapping | undefined;
  let currentSessionStep: AgentSessionStep | undefined = undefined;

  if (structureData) {
    data = await applyMappingFromAI(agent_session_id, structureData);
  } else {
    const qaStructureStep = sessionData.steps.find(
      (step) => step.type === AgentSessionStepType.QA_EXCEL_STRUCTURE_MAPPING
    );
    if (
      !qaStructureStep ||
      qaStructureStep.status !== AgentSessionStatus.COMPLETE
    )
      return;
    data = await applyMappingFromAI(
      agent_session_id,
      qaStructureStep.data.output.structure
    );
  }

  const updatedSteps = sessionData.steps.reduce((acc, step) => {
    if (step.type === AgentSessionStepType.QA_EXCEL_STRUCTURE_MAPPING) {
      step.status = AgentSessionStatus.COMPLETE;
    }

    if (step.type === AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING) {
      currentSessionStep = {
        ...step,
        data: {
          ...step.data,
          input: data,
        },
        status: AgentSessionStatus.INPUT_NEEDED,
      };
      acc.push(currentSessionStep);
      return acc;
    }
    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);
  if (currentSessionStep) await updateAgentSessionStep(currentSessionStep);
  updateAgentStepData(agent_session_id, updatedSteps);
  if (shouldNavigate && navigate)
    navigate({
      pathname: `/${ROUTES.AGENT}/${agent_session_id}`,
    });
};
interface FinalQuestionReviewResponse extends QuestionReviewReponse {
  approved: boolean;
  edited: boolean;
  id: string;
}

export const generateFinalQAJSON = (agentId: string) => {
  const agentData = getAgentData<
    AGENT_TYPES.QUESTIONNAIRE,
    QuestionnaireTypes.QA
  >(agentId);
  if (!agentData) {
    throw new Error('An error occurred');
  }
  const { mainData } = agentData;
  const { reviewResponseData } = mainData;
  if (!reviewResponseData) {
    throw new Error('An error occurred');
  }
  const approvedIds = mainData.approvedIds;
  const editedIds = mainData.editedIds;
  const finalQuestionJson: FinalQuestionReviewResponse[] = [];
  reviewResponseData.forEach((item, id) => {
    const question: FinalQuestionReviewResponse = {
      approved: approvedIds.includes(id),
      edited: editedIds.includes(id),
      id,
      answer: item.find((item) => item.key === 'answer')?.value as string,
      comment: item.find((item) => item.key === 'comment')?.value as string,
      justification: item.find((item) => item.key === 'justification')
        ?.value as string,
      sources: item.find((item) => item.key === 'sources')
        ?.value as ReviewSourceTypes[],
      confidence: item.find((item) => item.key === 'confidence')
        ?.value as ConfidenceTypes,
      question_id: item.find((item) => item.key === 'question_id')
        ?.value as string,
      question_text: item.find((item) => item.key === 'question_text')
        ?.value as string,
    };
    finalQuestionJson.push(question);
  });
  return finalQuestionJson;
};

export const handleAutoSave = async (
  agentId: string,
  markAsComplete?: boolean
) => {
  const questions = JSON.stringify(generateFinalQAJSON(agentId));
  const blob = new Blob([questions], { type: 'application/json' });
  const agentData = getAgentData<
    AGENT_TYPES.QUESTIONNAIRE,
    QuestionnaireTypes.QA
  >(agentId);
  if (!agentData) {
    throw new Error('An error occurred');
  }
  const { stepData } = agentData;
  const { staleUrl } = agentData.mainData;

  const { setStaleUrl, updateAgentStepData } = getAgentStateActions();
  const editStepData = stepData?.find(
    (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
  );

  if (!editStepData) {
    throw new Error('An occurred while saving');
  }

  let markAsCompleteDone = false;

  const stepUrl = editStepData?.data?.url;
  let currentStaleUrl = staleUrl || stepUrl;

  if (!currentStaleUrl) {
    const signedUrl = await getSignedUrl({
      file_names: ['updated_questions.json'],
      max_age: 86400,
    });
    const updatedStep = {
      ...editStepData,
      data: {
        url: signedUrl[0],
      },
      status: markAsComplete
        ? AgentSessionStatus.COMPLETE
        : AgentSessionStatus.IN_PROGRESS,
    };
    const updatedSteps = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedSteps);
    await updateAgentSessionStep(updatedStep);
    currentStaleUrl = signedUrl[0];
    markAsCompleteDone = true;
    setStaleUrl(agentId, currentStaleUrl);
  }
  const expiryDate = new URL(currentStaleUrl).searchParams.get('se');
  if (expiryDate) {
    const expiry = new Date(expiryDate);
    const currentTime = new Date();
    const diff = expiry.getTime() - currentTime.getTime();
    if (diff < 0) {
      const signedUrl = await getSignedUrl({
        stale_urls: [currentStaleUrl],
        max_age: 86400,
      });
      currentStaleUrl = signedUrl[0];
      setStaleUrl(agentId, currentStaleUrl);
    }
  }
  await uploadFileReq(currentStaleUrl, blob);
  if (markAsComplete && !markAsCompleteDone) {
    const updatedStep = {
      ...editStepData,
      data: {
        url: currentStaleUrl,
      },
      status: AgentSessionStatus.COMPLETE,
    };
    await updateAgentSessionStep(updatedStep);
    const updatedStepData = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedStepData);
  }
};

export const getQAJsonFromAPI = async (agentId: string) => {
  try {
    const questions = JSON.stringify(generateFinalQAJSON(agentId));
    const blob = new Blob([questions], { type: 'application/json' });

    const url = await getSignedUrl({
      file_names: ['updated_questions.json'],
    });
    await uploadFileReq(url[0], blob);
    const agentData = getAgentData<
      AGENT_TYPES.QUESTIONNAIRE,
      QuestionnaireTypes.QA
    >(agentId);
    if (!agentData) {
      throw new Error('An error occurred');
    }
    const { mainData, sessionData, stepData } = agentData;
    const excel_metadata = mainData.questionnaireFiller;
    const questionnaireFile = mainData.questionnaireFileData;
    const workflowName = sessionData.name;
    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();

    const data = await getExcelFromJSON({
      client_id: 'frontend',
      excel_url: questionnaireFile.url,
      excel_metadata,
      json_url: url[0],
    });

    setFinalFileUrl(agentId, data.message);
    const response = await fetch(data.message);
    const fileBlob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `${workflowName}_questionnaire.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    addNotification({
      type: 'success',
      title: 'Downloading your Report...',
      message:
        'If your report download does not start within 15 seconds, please use the download button',
    });

    const downloadStepData = stepData?.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT
    );
    const updatedStepData = stepData.map((step) => {
      if (step.id === downloadStepData?.id) {
        return { ...step, status: AgentSessionStatus.COMPLETE };
      }
      return step;
    });

    if (downloadStepData?.id) {
      await updateAgentSessionStep({
        ...downloadStepData,
        data: {
          url: data.message,
        },
        status: AgentSessionStatus.COMPLETE,
      });
    }

    updateAgentStepData(agentId, updatedStepData);
  } catch (e) {
    addNotification({
      message: 'Error in downloading the file',
      type: 'error',
    });
  }
};
