import { ReviewResponseType } from '../types';

export const getRenderType = (key: string) => {
  if (['risk_value'].includes(key)) {
    return ReviewResponseType.NUMBER;
  }

  if (['justification'].includes(key)) {
    return ReviewResponseType.TEXT_DISPLAY;
  }

  if (['compliance_status', 'compliant'].includes(key)) {
    return ReviewResponseType.COMPLIANCE_STATUS;
  }

  if (['sources'].includes(key)) {
    return ReviewResponseType.SOURCE;
  }

  return ReviewResponseType.TEXT;
};
