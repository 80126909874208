/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { Member } from '@stytch/vanilla-js';
import { TeamRole } from '../types';

export const isTeamOrgAdmin = (roles: TeamRole[]) => {
  const roleId = TeamRole.ORG_ADMIN;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const isTeamAdmin = (roles: TeamRole[]) => {
  const roleId = TeamRole.ADMIN;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const isTeamMember = (roles: TeamRole[]) => {
  const roleId = TeamRole.MEMBER;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessZaniaAgent = (roles: TeamRole[]) => {
  const roleId = TeamRole.ZANIA_AGENT;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessZaniaAccurateResponse = (roles: TeamRole[]) => {
  const roleId = TeamRole.ZANIA_ACCURATE_RESPONSE;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessZaniaRiskAssessment = (roles: TeamRole[]) => {
  const roleId = TeamRole.ZANIA_RISK_ASSESSMENT;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessZaniaDoraAssessment = (roles: TeamRole[]) => {
  const roleId = TeamRole.ZANIA_DORA_ASSESSMENT;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessZaniaFileUpload = (roles: TeamRole[]) => {
  const roleId = TeamRole.ZANIA_FILE_UPLOAD;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessZaniaQaAssessment = (roles: TeamRole[]) => {
  const roleId = TeamRole.ZANIA_QA_ACCESS;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessTeamSearch = (roles: TeamRole[]) => {
  const roleId = TeamRole.ZANIA_SEARCH_TEAM;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessQualityAccuracy = (roles: TeamRole[]) => {
  const roleId = TeamRole.RESPONSE_QUALITY_ACCURACY;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const canAccessQualitySpeed = (roles: TeamRole[]) => {
  const roleId = TeamRole.RESPONSE_QUALITY_SPEED;
  return { roleId, hasAccess: roles.includes(roleId) };
};

export const mapIsCurrentMemberOrgAdmin = (
  teams: Member[],
  memberId: string | undefined
) => {
  const currentTeamObject = teams.find((team) => team.member_id === memberId);
  return (
    currentTeamObject?.roles?.some(
      ({ role_id }) => role_id === TeamRole.ORG_ADMIN
    ) || false
  );
};

export const printErrorMessage = (error: string) => {
  return JSON.parse(error).status_code === 403
    ? 'Unauthorised user'
    : 'Something went wrong. Please try again later!';
};
